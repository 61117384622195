import { ButtonProps, makeStyles } from "@material-ui/core";
import MuiLink, { LinkClassKey as MuiLinkClassKey, LinkProps as MuiLinkProps } from "@material-ui/core/Link";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import NextLink, { LinkProps as BaseNextLinkProps } from "next/link";
import React, { useEffect, useMemo } from "react";
import { Url } from "url";
import { flatten, useOurRouter } from "../hooks/useOurRouter";

const LOCAL_LINK_PATTERNS: (RegExp | string)[] = [/^(\w+\.)?api\.reclaim(\-test)?\.com/, /^api\.app\.reclaim\.ai/];

const useStyles = makeStyles(
  (theme) => ({
    disabled: {
      opacity: 0.5,
      pointerEvents: "none",
    },
  }),
  {
    classNamePrefix: "Link",
  }
);

export type LinkJSSClassKey = keyof ReturnType<typeof useStyles> & MuiLinkClassKey;

type NextLinkProps = Omit<MuiLinkProps, "href"> &
  BaseNextLinkProps & {
    activeClassName?: string;
    naked?: boolean;
    href: string | Url;
  };

const NextComposed = React.forwardRef<HTMLSpanElement, NextLinkProps>(({ href, as, ...rest }, ref) => {
  return (
    <NextLink href={href} as={as} passHref>
      <MuiLink ref={ref} {...rest} />
    </NextLink>
  );
});

export type LinkProps = Omit<NextLinkProps, "href" | "variant" | "classes"> & {
  classes?: Partial<ClassNameMap<LinkJSSClassKey>>;
  href?: NextLinkProps["href"];
  variant?: MuiLinkProps["variant"] | ButtonProps["variant"];
  disabled?: boolean;
  // TODO: need better typing
  component?: unknown;
};

// A styled version of the Next.js Link component:
// https://nextjs.org/docs/#with-link
export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      classes: extClasses,
      href,
      activeClassName = "active",
      className: classNameProps,
      target,
      naked,
      rel,
      disabled,
      /**
       * props below here and above "...rest" aren't
       * used by Links, but are used by Button when
       * component is set to Link.  We need to include
       * them in LinkProps and remove them so they
       * don't get sent to MuiLink
       */
      variant,
      ...rest
    },
    ref
  ) => {
    const router = useOurRouter();
    const classes = useStyles({
      classes: extClasses,
    });

    // Jump link anchors don't work in Next
    // See here: https://github.com/vercel/next.js/issues/11109
    // useEffect to catch hash and scroll to section
    const locationHash = typeof window === "object" ? window.location.hash : undefined;

    useEffect(() => {
      if (!!locationHash) {
        setTimeout(() => {
          document.querySelector(locationHash)?.scrollIntoView({ behavior: "smooth" });
        }, 100);
      }
    }, [locationHash]);

    const pathname = useMemo(() => (!href || typeof href === "string" ? href : href.pathname) || "", [href]);

    const sameDomain = useMemo<boolean>(() => {
      if (typeof window === "undefined") return true;
      if (!pathname) return true;
      const { hostname } = new URL(pathname, window.location.href);

      if (hostname === window.location.hostname) return true;

      return !LOCAL_LINK_PATTERNS.every((regex) =>
        typeof regex === "string" ? regex !== hostname : !hostname.match(regex)
      );
    }, [pathname]);

    const className = clsx(classNameProps, {
      [activeClassName]: router.pathname === pathname && activeClassName,
      [classes.disabled]: disabled,
    });

    const muiLinkProps: MuiLinkProps = {
      className,
      ref,
      target: target || (sameDomain ? undefined : "_blank"),
      rel: rel || (sameDomain ? undefined : "noreferrer"),
    };

    if (!!href && typeof href !== "string" && !!href.query)
      href.query = flatten(typeof href.query === "string" ? JSON.parse(href.query) : href.query) || null;

    if (!href) {
      return <MuiLink {...muiLinkProps} {...rest} />;
    }

    if (pathname.startsWith("http") || pathname.startsWith("//") || pathname.startsWith("mailto")) {
      return <MuiLink {...muiLinkProps} href={pathname} {...rest} />;
    }

    if (!naked) {
      return <NextComposed {...muiLinkProps} href={href} {...rest} />;
    }

    return <MuiLink {...muiLinkProps} component={NextComposed} href={pathname} {...rest} />;
  }
);
